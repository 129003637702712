.clients-section {
    padding: 60px 0;
    background: #fff;
    overflow: hidden;
  }
  
  .clients-title {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .clients-subtitle {
    text-align: center;
    color: #666;
    margin-bottom: 50px;
    font-size: 1.1rem;
  }
  
  .logos-container {
    width: 100%;
    overflow: hidden;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    background: linear-gradient(90deg, 
      #fff 0%, 
      rgba(255,255,255,0) 2%, 
      rgba(255,255,255,0) 98%, 
      #fff 100%
    );
  }
  
  .logos-scroll {
    display: flex;
    animation: scroll 30s linear infinite;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .logo-item {
    flex: 0 0 auto;
    width: 150px;
    height: 80px;
    padding: 10px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0,0,0,0.1);
  }
  
  .logo-item img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    /* filter: grayscale(100%); */
    transition: filter 0.3s ease;
  }
  
  /* .logo-item:hover img {
    filter: grayscale(0%);
  } */
  
  /* @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-50% - 20px));
    }
  } */
  
  .logos-container:hover .logos-scroll {
    animation-play-state: paused;
  }
  
  @media (max-width: 768px) {
    .clients-section {
      padding: 40px 0;
    }
  
    .clients-title {
      font-size: 2rem;
    }
  
    .logo-item {
      width: 120px;
      height: 60px;
    }
  }