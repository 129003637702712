.contact-section {
    padding: 80px 20px;
    background: #fff;
  }
  
  .section-title {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .section-title h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 15px;
    position: relative;
  }
  
  .section-title h2::after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: #FBBF24;
  }
  
  .contact-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 50px;
  }
  
  .contact-info {
    padding: 30px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }
  
  .contact-info h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .contact-info > p {
    color: #666;
    margin-bottom: 30px;
  }
  
  .contact-details {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  
  .contact-item {
    display: flex;
    align-items: flex-start;
    gap: 15px;
  }
  
  .contact-icon {
    color: #FBBF24;
    flex-shrink: 0;
  }
  
  .contact-item h4 {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 5px;
  }
  
  .contact-item p {
    color: #666;
    font-size: 1rem;
  }
  
  .contact-form {
    padding: 30px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  input, textarea {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  input:focus, textarea:focus {
    outline: none;
    border-color: #FBBF24;
    box-shadow: 0 0 0 2px rgba(251, 191, 36, 0.2);
  }
  
  .submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding: 12px 25px;
    background: #FBBF24;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .submit-btn:hover {
    background: #F59E0B;
    transform: translateY(-2px);
  }
  
  .submit-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
    transform: none;
  }
  
  .send-icon {
    transition: transform 0.3s ease;
  }
  
  .submit-btn:hover .send-icon {
    transform: translateX(5px);
  }
  
  .success-message {
    margin-top: 15px;
    padding: 10px;
    background: #10B981;
    color: white;
    border-radius: 8px;
    text-align: center;
  }
  
  .error-message {
    margin-top: 15px;
    padding: 10px;
    background: #EF4444;
    color: white;
    border-radius: 8px;
    text-align: center;
  }



input.error,
textarea.error {
  border-color: #EF4444;
  background-color: #FEF2F2;
}

input.error:focus,
textarea.error:focus {
  box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.2);
}

.error-text {
  color: #EF4444;
  font-size: 0.875rem;
  margin-top: 5px;
  margin-left: 5px;
}

.char-count {
  font-size: 0.75rem;
  color: #666;
  text-align: right;
  margin-top: 4px;
}

.char-count.limit {
  color: #EF4444;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #FBBF24;
  box-shadow: 0 0 0 2px rgba(251, 191, 36, 0.2);
}
  
  @media (max-width: 768px) {
    .contact-container {
      grid-template-columns: 1fr;
    }
  
    .form-row {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .contact-section {
      padding: 40px 15px;
    }
  
    .section-title h2 {
      font-size: 2rem;
    }
  
    .contact-info, .contact-form {
      padding: 20px;
    }
  }