.footer {
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    color: #333;
    padding-top: 60px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 -8px 32px rgba(0, 0, 0, 0.08);
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
  }
  
  .company-info {
    max-width: 300px;
  }
  
  .footer-logo {
    height: 60px;
    margin-bottom: 20px;
  }
  
  .company-info p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
    font-size: 0.9rem;
    position: relative;
    display: inline-block;

    &::after {
        content: '';
        display: block;
        width: 100%; /* Matches the width of the text content */
        height: 3px;
        background: #FBBF24;
        margin: 15px 0 0 0; /* Adjust margin to space the line */
      }


  }
  
  .social-links {
    display: flex;
    gap: 15px;
  }
  
  .social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.05);
    color: #333;
    transition: all 0.3s ease;
  }
  
  .social-link:hover {
    background: #FBBF24;
    color: white;
    transform: translateY(-3px);
  }
  
  .footer-section h3 {
    color: #333;
    font-size: 1.2rem;
    margin-bottom: 20px;
    position: relative;
    font-weight: 600;
  }
  
  .footer-section h3::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 30px;
    height: 2px;
    background: #FBBF24;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 12px;
  }
  
  .footer-links a {
    color: #666;
    text-decoration: none;
    transition: all 0.3s ease;
    font-size: 0.9rem;
  }
  
  .footer-links a:hover {
    color: #FBBF24;
    padding-left: 5px;
  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #666;
    font-size: 0.9rem;
  }
  
  .contact-item svg {
    color: #FBBF24;
    flex-shrink: 0;
  }
  
  .footer-bottom {
    margin-top: 60px;
    padding: 20px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.9);
  }
  
  .footer-bottom-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-bottom p {
    color: #666;
    font-size: 0.9rem;
  }
  
  .footer-bottom-links {
    display: flex;
    gap: 20px;
  }
  
  .footer-bottom-links a {
    color: #666;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;
  }
  
  .footer-bottom-links a:hover {
    color: #FBBF24;
  }
  
  @media (max-width: 1024px) {
    .footer-content {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .footer-content {
      grid-template-columns: 1fr;
    }
  
    .footer-section {
      text-align: center;
    }
  
    .footer-section h3::after {
      left: 50%;
      transform: translateX(-50%);
    }
  
    .social-links {
      justify-content: center;
    }
  
    .contact-info {
      align-items: center;
    }
  
    .footer-bottom-content {
      flex-direction: column;
      gap: 15px;
      text-align: center;
    }
  }
  
  @media (max-width: 480px) {
    .footer {
      padding-top: 40px;
    }
  
    .footer-bottom {
      margin-top: 40px;
    }
  }